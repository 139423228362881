<template>
  <div>
    <v-flex v-if="err" class="text-xs-center" mt-5>
     <v-alert style="white-space:pre-wrap"
       name="error"
       v-bind:err="err"
       v-bind:vm="vm"
       v-bind:info="info"
       type="error"
     >{{error}}</v-alert>
    </v-flex>
    <slot v-else></slot>
  </div>
</template>

<script>
export default {
  name: "error-boundary",
  props: {
    stopPropagation: Boolean
  },
  data() {
    return {
      err: false,
      vm: null,
      info: null,
      error: null
    };
  },
  errorCaptured(err, vm, info) {
    this.err = err;
    this.error = err.message;
    this.vm = vm;
    this.info = info;
    return !this.stopPropagation;
  }
};
</script>
