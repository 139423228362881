<template>
  <v-app>
    <error-boundary>
      <HeadNavi
          :appTitle="appTitle"
          :isLoggedIn="isLoggedIn"
          :role="role || 'public'"
      />

      <v-main>
        <v-container fluid>
          <v-row>
            <v-col class="text-xs-center" mt-5 xs12>
              <router-view
                  :isLoggedIn="isLoggedIn"
                  :routeName="routeName"
                  @changeLoginState="changeLoginState">
              </router-view>
            </v-col>

          </v-row>
        </v-container>
      </v-main>
    </error-boundary>
  </v-app>
</template>
<script>
import HeadNavi from './components/navi/HeadNavi';
import errorBoundary from './components/functions/error-boundary';
//import model from './helpers/model';
//import {HTTP} from '@/axios';
import axios from "axios";

const checkIfTokenIsThere = function () {
  return typeof localStorage.getItem('token') === "string" && localStorage.getItem('token') !== ''
}

export default {
  name: "App",
  components:{HeadNavi,errorBoundary},
  data(){
    return {
      print: false,
      appTitle: 'GVNRW-Portal',
      connecting: false,
      isLoggedIn: false,
      role: localStorage.getItem("role")
    }
  },
  computed:{
    routeName(){
      return this.$route.name;
    }
  },
  methods: {
    changeLoginState(token) {
      //custom event function to change login state in app data after logging in or out
      this.isLoggedIn = checkIfTokenIsThere();
      this.role = localStorage.getItem("role");
      axios.defaults.headers.common['Authorization'] = token || localStorage.getItem('token');
    }
  },
  mounted(){
    this.$nextTick(function () {
      //recover logged in state in app data
      this.isLoggedIn = checkIfTokenIsThere();
    });
   /* HTTP
        .get('/mein-verein/5fcf9eedd16bbba2b7ca8e86')
        .then(response => (console.log(response)));*/

    /*HTTP
        .get('/users-permissions/permissions')
        .then(response => (console.log(response)));*/

  }
}
</script>
<style>
blockquote {
  padding-left: 1rem;
  border-left: 3px solid rgba(9, 8, 8, 0.98);
}

mark {
  padding: 0.125em;
  border-radius: 0.25em;
  box-decoration-break: clone;
}

.pointer, .v-btn {
  cursor: pointer;
}
</style>
