import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import DatetimePicker from 'vuetify-datetime-picker';

import de from 'vuetify/lib/locale/de'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader


Vue.use(Vuetify);
Vue.use(DatetimePicker);


export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  lang: {
    locales: {de},
    current: 'de',
  },
  theme: {
    options: {customProperties: true},
    dark: process.env.VUE_APP_THEME === "dark",
    themes: {
      light: {
        primary: '#666666', //'#9bbfaf',
        secondary: '#44a181',
        accent: '#fc5753',//'#9bbfaf',
        error: '#ff5722',
      },
      dark: {
        primary: '#454545', //'#9bbfaf',
        secondary: '#44a181',
        accent: '#fc5753',//'#9bbfaf',
        error: '#ff5722',
      },
    },
  }
});
