export default [
  {
    title: "Stammdaten",
    path: "/spieler",
    icon: "mdi-contact-mail",
    visible: "isSpieler",
  },
  {
    title: "Mein Verein",
    path: "/verein",
    icon: "mdi-contact-mail",
    visible: "isVerein",
  },
  {
    title: "Alle Vereine",
    path: "/alle-vereine",
    icon: "mdi-golf",
    visible: "isVerein",
  },
  {
    title: "Alle Wettspiele",
    path: "/alle-wettspiele",
    icon: "mdi-medal",
    visible: "isVerein",
  },


  /**
   *  Referee ----------------------------------------
   */
  {
    title: "Alle Wettspiele",
    path: "/alle-wettspiele",
    icon: "mdi-medal-outline",
    visible: "isReferee",
  },
  //{title: 'Meine Wettspiele', path: '/meine-wettspiele', icon: 'mdi-medal', visible: 'isReferee'},
  {
    title: "Meine Wettspiele",
    path: "/meine-wettspiele",
    icon: "mdi-medal",
    visible: "isReferee",
  },
  {
    title: "Abrechnung",
    path: "/abrechnung",
    icon: "mdi-account-cash",
    visible: "isReferee",
  },
  {
    title: "Hotelbewertung",
    path: "/hotelbewertung",
    icon: "mdi-star",
    visible: "isReferee",
  },
  {
    title: "Informationen",
    path: "/ref-informationen",
    icon: "mdi-folder-information",
    visible: "isReferee",
  },

  /**
   * ------------------------------------------------------
   */

  /**
   *  Scoring ----------------------------------------
    */
  {
    title: "Alle Wettspiele",
    path: "/alle-wettspiele-scoring",
    icon: "mdi-medal-outline",
    visible: "isScoring",
  },

  {
    title: "Meine Wettspiele",
    path: "/meine-wettspiele-scoring",
    icon: "mdi-medal",
    visible: "isScoring",
  },

  {
    title: "Hotelbewertung",
    path: "/hotelbewertung",
    icon: "mdi-star",
    visible: "isScoring",
  },

  /**
   * ------------------------------------------------------
   */

  {
    title: "Vereine",
    path: "/vereine",
    icon: "mdi-golf",
    visible: "isAppAdmin",
  },
  {
    title: "Mannschaft",
    path: "/mannschaft",
    icon: "mdi-account-group",
    visible: "isAppAdmin",
  },
  // {title: 'Hotels', path: '/hotels', icon: 'mdi-account-group', visible: 'isAppAdmin'},
  {
    title: "Turniere",
    path: "/turniere",
    icon: "mdi-golf-tee",
    visible: "isAppAdmin",
  },
  {
    title: "Wettspiele",
    path: "/wettspiele",
    icon: "mdi-medal",
    visible: "isAppAdmin",
  },
  {
    title: "Offene Abrechnungen",
    path: "/abrechnungen",
    icon: "mdi-receipt",
    visible: "isAppAdmin",
  },
  {
    title: "Kontakte",
    path: "/kontakte",
    icon: "mdi-contact-mail",
    visible: "isAppAdmin",
  },
  {
    title: "Historie",
    path: "/historie",
    icon: "mdi-history",
    visible: "isAppAdmin",
  },
  {
    title: "Kampagnen",
    path: "/campaigns",
    icon: "mdi-bullhorn",
    visible: "isAppAdmin",
  },
  { title: "Emails", path: "/emails", icon: "mdi-at", visible: "isAppAdmin" },
  {
    title: "Rechnungen",
    path: "/Rechnungen",
    icon: "mdi-account-cash",
    visible: "isAppAdmin",
  },
  {
    title: "Einstellungen",
    path: "/einstellungen",
    icon: "mdi-cogs",
    visible: "isAppAdmin",
  },
  {
    title: "Benutzer",
    path: "/benutzer",
    icon: "mdi-account-tie",
    visible: "isAppAdmin",
  },
  {
    title: "Hotelbewertungen",
    path: "/hotelbewertungen",
    icon: "mdi-home-city",
    visible: "isAppAdmin",
  },
  {
    title: "Landeskader",
    path: "/landeskader",
    icon: "mdi-account-eye",
    visible: "isAppAdmin",
  },
  {
    title: "Ref Informationen",
    path: "/ref-informationen",
    icon: "mdi-folder-information",
    visible: "isAppAdmin",
  },


  // { title: 'Sign Up', path: '/benutzer?new', icon: 'face', visible: 'canCreateUsers' },
  {
    title: "Anmelden",
    path: "/signin",
    icon: "lock_open",
    invisible: "isLoggedIn",
  },
  {
    title: "Abmelden",
    path: "/logout",
    icon: "lock_open",
    visible: "isLoggedIn",
  },
];
