import Vue from 'vue'
import App from './App.vue'

import VueApollo from "vue-apollo";
import apolloClient from "./vue-apollo";
import vuetify from './plugins/vuetify';
import router from "./router";
import VueHtmlToPaper from 'vue-html-to-paper';
import moment from 'moment'
import i18n from './i18n'

Vue.config.productionTip = false;
Vue.prototype.moment = moment

Vue.use(VueApollo);
const printOptions = {
    name: '_blank',
    specs: [
        'titlebar=no',
        'scrollbars=no'
    ]
}
Vue.use(VueHtmlToPaper,printOptions);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
     $query: {
       fetchPolicy: 'cache-and-network'
     }
   },
  /* errorHandler (error) {
     //filter array issue
     console.log("apolloProvider errorHandler (src/main.js):");
     console.error(error);
   }*/
});

new Vue({
    apolloProvider,
    vuetify,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
