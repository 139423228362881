<template>
  <div>
    <v-system-bar app>
      <v-spacer></v-spacer>
      <v-icon v-if="!isLoggedIn">mdi-lan-disconnect</v-icon>
      <v-icon v-if="$apollo.loading">mdi-lan-pending</v-icon>
      <v-icon v-if="isLoggedIn">mdi-lan-connect</v-icon>
    </v-system-bar>

    <v-app-bar app dense>
      <v-app-bar-nav-icon :class="NavClass.mobile" @click="drawer = !drawer"></v-app-bar-nav-icon>
      <router-link to="/">
        <v-img
          class="mx-2"
          src="/logo.png"
          max-height="80"
          max-width="80"
          contain
        ></v-img>
      </router-link>
      <v-toolbar-title>{{ appTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items :class="NavClass.desktop">
        <v-btn
            v-for="item in activeMenu"
            :key="item.title+role"
            :to="item.path"
            small
        >
          <v-icon left dark>{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
    >
      <v-list>
        <v-list-item
          v-for="item in activeMenu"
          :key="item.title+role+'d'"
          :to="item.path"
        >
          <v-list-item-icon >
            <v-icon class="pa-0 ma-0">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>
  </div>
</template>
<script>
import navStructure from "@/config/nav/navStructure";

export default {
  name: 'HeadNavi',
  props: {
    role:{
      type: String,
      required: false
    },
    isLoggedIn: {
      type: Boolean,
      required: true
    },
    appTitle:{
      type: String,
      required: false
    }
  },
  data () {
    return {
      drawer: null
    }
  },
  computed: {
    NavClass() {
      //from how many menu items it will break...
      //could be better when the actual px width of the nav bar will be the trigger
      const breakPoints = {
        4: {
          mobile: "hidden-sm-and-up",
          desktop: "hidden-xs-only"
        },
        10: {
          mobile: "hidden-lg-and-up",
          desktop: "hidden-md-and-down"
        },
        99: {
          mobile: "hidden-xl-only",
          desktop: "hidden-lg-and-down"
        }
      };
      let bpKey = this.activeMenu.length;
      while (breakPoints[bpKey] === undefined) {
        bpKey++;
      }
      return breakPoints[bpKey];
    },
    isSpieler() {
      return this.role === "spieler" && this.isLoggedIn
    },
    isVerein() {
      return this.role === "verein" && this.isLoggedIn
    },
    isReferee() {
      return this.role === "referee" && this.isLoggedIn
    },
    isScoring() {
      return this.role === "scoring" && this.isLoggedIn
    },
    isAppAdmin() {
      return this.role === "AppAdmin" && this.isLoggedIn
    },
    /*canCreateUsers(){
      return this.rights.createUsers === true;
    },*/
    activeMenu() {//function to display menu items
      let data = this;
      this.role; // is referenced to rerender menu on role change
      return navStructure.filter(function (item) {
        return item.visible === undefined && item.invisible === undefined || (item.visible !== undefined && data[item.visible]) || (item.invisible !== undefined && !data[item.invisible]);
      });
    }
  }
}
</script>
