import Router from "vue-router";
import Vue from "vue";

const routerOptions = [
  { path: "/", component: "Landing" },
  { path: "/signin", component: "SignIn" },
  { path: "/logout", component: "LogOut" },
  { path: "/signup", component: "SignUp" },
  //EmailEditor
  {
    path: "/email-editor",
    component: "TemplateEditor",
    name: "E-Mail-Editor",
    meta: { requiresAuth: true },
  },
  { path: "/request-password", component: "RequestPassword" },
  { path: "/reset-password", component: "ResetPassword" },
  {
    path: "/benutzer",
    component: "MainList",
    name: "Benutzer",
    meta: { requiresAuth: true },
  },
  {
    path: "/vereine",
    component: "MainList",
    name: "Vereine",
    meta: { requiresAuth: true },
  },
  {
    path: "/alle-vereine",
    component: "MainList",
    name: "Alle-Vereine",
    meta: { requiresAuth: true },
  },
  {
    path: "/alle-wettspiele",
    component: "MainList",
    name: "Alle-Wettspiele",
    meta: { requiresAuth: true },
  },
  {
    path: "/alle-wettspiele-scoring",
    component: "MainList",
    name: "Alle-Wettspiele-Scoring",
    meta: { requiresAuth: true },
  },

  {
    path: "/meine-wettspiele-scoring",
    component: "MainList",
    name: "WettspieleScoring",
    meta: { requiresAuth: true },
  },
  {
    path: "/meine-wettspiele",
    component: "MainList",
    name: "WettspieleReferee",
    meta: { requiresAuth: true },
  },

  {
    path: "/ref-informationen",
    component: "RefInformationen",
    name: "RefInformationen",
    meta: { requiresAuth: true },
  },


  // {path: "/meine-wettspiele", component: "WettspieleReferee", meta: {requiresAuth: true}},
  {
    path: "/alle-wettspiele",
    component: "MainList",
    name: "WettspieleVerein",
    meta: { requiresAuth: true },
  },

  {
    path: "/verein",
    component: "MyClub",
    name: "Mein-Verein",
    meta: { requiresAuth: true },
  },
  {
    path: "/ergebnislisten",
    component: "MainList",
    name: "Ergebnislisten",
    meta: { requiresAuth: true },
  },
  //{path: "/hotels", component: "MainList", name: "Hotel", meta: {requiresAuth: true}},
  {
    path: "/turniere",
    component: "MainList",
    name: "Turniere",
    meta: { requiresAuth: true },
  },
  {
    path: "/wettspiele",
    component: "MainList",
    name: "Wettspiele",
    meta: { requiresAuth: true },
  },
  {
    path: "/abrechnungen",
    component: "Invoices",
    meta: { requiresAuth: true },
  },
  {
    path: "/spieler",
    component: "Spieler",
    name: "Spieler",
    meta: { requiresAuth: true },
  },
  {
    path: "/mannschaft",
    component: "MainList",
    name: "Mannschaft",
    meta: { requiresAuth: true },
  },
  {
    path: "/kontakte",
    component: "MainList",
    name: "Kontakte",
    meta: { requiresAuth: true },
  },
  {
    path: "/historie",
    component: "MainList",
    name: "Historie",
    meta: { requiresAuth: true },
  },
  {
    path: "/einstellungen",
    component: "MainList",
    name: "Einstellungen",
    meta: { requiresAuth: true },
  },
  {
    path: "/campaigns",
    component: "MainList",
    name: "Kampagnen",
    meta: { requiresAuth: true },
  },
  {
    path: "/emails",
    component: "MainList",
    name: "E-Mails",
    meta: { requiresAuth: true },
  },
  {
    path: "/hotelbewertungen",
    component: "MainList",
    name: "Hotelbewertungen",
    meta: { requiresAuth: true },
  },
  {
    path: "/rechnungen",
    component: "MainList",
    name: "Rechnungen",
    meta: { requiresAuth: true },
  },
  { path: "/abrechnung",
    component: "Invioce",
    meta: { requiresAuth: true } },
  {
    path: "/hotelbewertung",
    component: "HotelReview",
    meta: { requiresAuth: true },
  },
  {
    path: "/landeskader",
    component: "Landeskader",
    meta: { requiresAuth: true },
  },
  { path: "/dev", component: "dev", name: "dev", meta: { requiresAuth: true } },
  { path: "*", component: "NotFound" },
];

const routes = routerOptions.map((route) => {
  return {
    ...route,
    component: () => import(`@/components/pages/${route.component}.vue`),
  };
});

Vue.use(Router);

export default new Router({
  mode: "history",
  routes,
});
